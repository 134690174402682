// Styles
import styles from "./styles.module.scss";

const Cards = ({ cards }: any) => {
  const openLink = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <div className={styles.__cards}>
      {cards.map(
        ({ name, description, image, github, link }: any, index: any) => (
          <button
            className={styles.__card}
            key={`project-${index}`}
            onClick={() => openLink(link)}
          >
            <div>
              <img className={styles.__logo} src={image} alt={name} />
            </div>
            <div className={styles.__details}>
              <h2>{name}</h2>
              <p>{description}</p>
            </div>
          </button>
        )
      )}
    </div>
  );
};

export default Cards;
