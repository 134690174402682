import { Link } from "react-router-dom";

// Styles
import styles from "./styles.module.scss";

const Navbar = ({ lang, setLang }: any) => {
  const changeLang = () => {
    if (lang === "fr") {
      setLang("en");
    } else {
      setLang("fr");
    }
  };

  return (
    <nav className={styles.__navbar}>
      <h1 className={styles.__name}>
        <Link to="/">Neka's Tools</Link>
      </h1>
      <ul className={styles.__links}>
        <li>
          <button className={styles.__lang} onClick={() => changeLang()}>
            {lang === "fr" ? "EN" : "FR"}
          </button>
        </li>
      </ul>
      <ul className={styles.__mobile_links}>
        <li>
          <button className={styles.__lang} onClick={() => changeLang()}>
            {lang === "fr" ? "EN" : "FR"}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
