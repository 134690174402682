import React from "react";

// Styles
import styles from "../../styles/pages/Home.module.scss";

// Components
import Cards from "../../components/Cards";

// Assets
import pwdgen from "../../assets/images/tools/pwdgen.webp";
import mailtester from "../../assets/images/tools/mailtester.webp";
import instagen from "../../assets/images/tools/instagen.webp";

const Home = ({ lang }: any) => {
  const cards = [
    {
      name: "PwdGen",
      description:
        lang === "fr"
          ? "Généré un password sécurisé rapidement."
          : "Generate a secure password quickly.",
      image: pwdgen,
      github: "https://github.com/Nekall/PwdGen",
      link: "https://nekall.github.io/PwdGen/",
    },
    {
      name: "InstaGen",
      description:
        lang === "fr"
          ? "Une aide pour générer vos token Instagram."
          : "A help to generate your Instagram tokens.",
      image: instagen,
      github: "https://github.com/Nekall/InstaGen",
      link: "https://instagen.vercel.app/",
    },
    {
      name: "Mailtester",
      description:
        lang === "fr"
          ? "Tester la véracité d'une liste de mails."
          : "Test the veracity of a list of emails.",
      image: mailtester,
      github: "https://github.com/Nekall/Mailtester-front",
      link: "https://mailtester-front.vercel.app/",
    },
  ];

  return (
    <div className={styles.__home}>
      <Cards cards={cards} />
    </div>
  );
};
export default Home;
